import React from "react";
import { DDI as Ddi } from "./DDI";
import Trunk from "./Trunk";

import {
  Card,
  Tab,
  Tabs,
  CardBody,
} from "@seveniteen/bootiquestrap-ui-library";

import Icon from "./ui/Icon";

const VoiceManagement: React.FC = () => {
  return (
    <div className="p-2 m-2">
      <div className="d-flex flex-row align-items-center">
        <Icon
          className="side-nav-link-icon"
          style={{ width: "30px", height: "30px" }}
          fill="black"
          name="voicemanagment"
        />
        <h3 className="p-3">Voice Management</h3>
      </div>
      <Card className="border-0">
        <Tabs className="nav nav-tab">
          <Tab className="nav nav-tab" title="DDI">
            <Ddi />
          </Tab>
          <Tab className="nav nav-tab" title="Trunk">
            <CardBody className="w-100 border-0 p-0">
              <Trunk />
            </CardBody>
          </Tab>
        </Tabs>
      </Card>
    </div>
  );
};

export default VoiceManagement;
