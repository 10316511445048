import React, { useState, useEffect, useContext } from "react";
import { ApplicationContext } from "../context/ApplicationContext";
import { Card, CardBody } from "@seveniteen/bootiquestrap-ui-library";
export interface NOCContactsProps {
  className?: string;
  children?: React.ReactNode;
}

const NOCContacts: React.FC = () => {
  const applicationContext = useContext(ApplicationContext);
  const [data, setData] = useState<any[]>([]);

  const loadData = () => {
    applicationContext.webApi
      ?.getNocContacts(applicationContext.customerId)
      .then((response) => {
        setData(response);
      });
  };

  useEffect(() => {
    loadData();
  }, [applicationContext.customerId]);

  return (
    <Card className="pb-0 pt-0 border-0">
      <CardBody>
        {data.map((noc) => {
          return (
            <React.Fragment key={"fragment_" + noc.name}>
              <div className="d-flex flex-row justify-content-start">
                <div className="flex-column" style={{ width: "30%" }}>
                  <p>{noc.name}</p>
                </div>
                <div className="flex-column ml-2" style={{ width: "70%" }}>
                  <p>
                    Email:
                    <br />
                    <a href={"mailto:" + noc.email}>{noc.email}</a>
                  </p>
                  {noc.telephoneAccesses.map((telephoneAccess: any) => {
                    return (
                      <p key={"support_" + telephoneAccess.id}>
                        {telephoneAccess.country + ": "}
                        <br />
                        <a href={"tel:" + telephoneAccess.number}>
                          {telephoneAccess.number}
                        </a>
                      </p>
                    );
                  })}
                </div>
              </div>
              <strong className="text-primary">Escalation List</strong>
              <br />
              <br />
              <div className="table-responsive ps-1 pt-3">
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th
                        className="text-primary p-0"
                        style={{ width: "10" }}
                        key="level">
                        Level
                      </th>
                      <th
                        className="text-primary p-0"
                        style={{ width: "45" }}
                        key="name">
                        Name
                      </th>
                      <th
                        className="text-primary p-0"
                        style={{ width: "45" }}
                        key="contact">
                        Contact Details
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {noc.incidentTeams.map((incidentTeam: any) => {
                      return (
                        <tr key={incidentTeam.id}>
                          <td className="p-0">{incidentTeam.incidentLevel}</td>
                          <td className="p-0">
                            {incidentTeam.name
                              .split("<br/>")
                              .map((item: any) => {
                                return (
                                  <p key={"item_" + item} className="m-0">
                                    {item}
                                  </p>
                                );
                              })}
                          </td>
                          <td className="p-0">
                            <p>
                              {incidentTeam.teamName}
                              <br />
                              <a href={"mailto:" + incidentTeam.email}>
                                {incidentTeam.email}
                              </a>
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </React.Fragment>
          );
        })}
      </CardBody>
    </Card>
  );
};

export default NOCContacts;
