import React from "react";
import { ReactComponent as SolidLock } from "./icons/SolidLock.svg";
import { Desktop, Mobile } from "../utils/breakpoints";

import "./ForgotPassword.css";

const ForgotPassword: React.FC = () => {
  const renderShapes = () => {
    return (
      <>
        <div
          id="forgot-password-shape-1"
          className="position-absolute rounded-circle"
        />
        <div
          id="forgot-password-shape-1-blur"
          className="position-absolute rounded-circle"
        />
        <div
          id="forgot-password-shape-2"
          className="position-absolute rounded-circle"
        />
        <div
          id="forgot-password-shape-2-blur"
          className="position-absolute rounded-circle"
        />
        <div
          id="forgot-password-shape-3"
          className="position-absolute rounded-circle"
        />
        <div
          id="forgot-password-shape-3-blur"
          className="position-absolute rounded-circle"
        />
        <div
          id="forgot-password-shape-4"
          className="position-absolute rounded-circle"
        />
        <div
          id="forgot-password-shape-4-blur"
          className="position-absolute rounded-circle"
        />
      </>
    );
  };

  const renderForgotPassForm = () => {
    return (
      <div className="card-body p-5 d-flex flex-column align-items-center">
        <div className="d-inline-flex align-self-center">
          <div
            id="lock-icon"
            className="rounded-circle strong-5-strong text-center me-2"
          >
            <SolidLock
              id="rounded"
              style={{
                color: "#D98D03",
                width: "20px",
                height: "20px",
              }}
            />
          </div>
          <h4 className="text-primary mb-3">Forgot password?</h4>
        </div>
        <p className="text-info text-center">
          Enter your email that you used to register, so we can send you a link
          to reset your password.
        </p>
        <form
          className={
            "forgot-password-form d-flex flex-column align-items-center mt-5 mb-4"
          }
        >
          <input
            type="email"
            name="email"
            id="email"
            className="form-control mb-5"
            placeholder="Enter email here"
            required
          />
          <span className="invalid-feedback">Email is required</span>
          <button type="submit" className="btn btn-primary w-100 mt-3">
            Send link
          </button>
        </form>
      </div>
    );
  };

  return (
    <>
      <Desktop>
        <div className="d-flex align-items-center justify-content-center h-100 overflow-y-hidden overflow-x-hidden">
          <div className="form position-relative">
            {renderShapes()}
            <div
              id="f-glass-card"
              className="card shadow-6 rounded-6 justify-content-center py-5 border-0"
            >
              {renderForgotPassForm()}
            </div>
          </div>
        </div>
      </Desktop>
      <Mobile>
        <div className="d-flex position-relative overflow-y-hidden overflow-x-hidden w-100 h-100">
          <div className="card position-absolute p-0 m-0 border-0 w-100 h-100">
            {renderShapes()}
            <div className="d-flex flex-column f-glass-card h-100 align-items-center justify-content-center">
              <div className="d-flex flex-row justify-content-center align-items-center m-1">
                {renderForgotPassForm()}
              </div>
            </div>
          </div>
        </div>
      </Mobile>
    </>
  );
};

export default ForgotPassword;
