import { useState } from "react";
import "./SideBar.css";
import { LargeDesktop } from "../utils/breakpoints";
import {
  SideNavButton,
  SideNav,
  SideNavLink,
  SideNavCat,
} from "@seveniteen/bootiquestrap-ui-library";

export interface SideBarProps {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  name?: string;
  sidebarOpen?: boolean;
}

const SideBar: React.FC<SideBarProps> = () => {
  const [isOpen, setIsOpen] = useState(true);

  const handleTrigger = () => {
    setIsOpen(!isOpen);
  };

  return (
    <SideNav
      className={`sidebar-container  ${
        isOpen ? "sidebar--open" : "sidebar--closed"
      }`}
      logo={isOpen ? "./images/RZ_weSystems_Logo_positiv.svg" : "./favicon.ico"}
      logoHeight={isOpen ? "70px" : "35px"}
      style={{ background: "#B8D4D9" }}
    >
      <LargeDesktop>
        <SideNavButton
          className="text-dark sidebar-position sidenavlink"
          image="hamburgerMenu"
          tooltip="Navigation Menu"
          sidebarOpen={isOpen}
          onClick={handleTrigger}
        />
      </LargeDesktop>

      {/* 1 */}
      <div>
        <SideNavLink
          className="sidebar-position text-dark"
          to="/"
          image="contactListIcon"
          tooltip="Contact List"
          sidebarOpen={isOpen}
        >
          <small
            className={`tooltip-link 
          ${isOpen ? "" : "navtext--closed"}
          `}
          >
            Contact List
          </small>
        </SideNavLink>
      </div>

      {/* 2 */}
      <div>
        <SideNavLink
          className="sidebar-position text-dark"
          to="/voicemanagement"
          image="voicemanagment"
          tooltip="Voice Management"
          sidebarOpen={isOpen}
        >
          <small
            className={`nav-text  
          ${isOpen ? "" : "navtext--closed"}
          `}
          >
            Voice Management
          </small>
        </SideNavLink>
      </div>

      {/* 3 */}
      <div>
        <SideNavLink
          className="sidebar-position text-dark"
          to="/voicemail"
          image="voicemail"
          tooltip="Sip trunk"
          sidebarOpen={isOpen}
        >
          <small
            className={`nav-text  
          ${isOpen ? "" : "navtext--closed"}
          `}
          >
            Sip Trunk
          </small>
        </SideNavLink>
      </div>

      {/* 4 */}
      <SideNavCat
        className={`nav-text sidenav-position text-dark
          ${isOpen ? "" : "navtext--closed"}
          `}
        name="Administration"
        image="administrationWeSystem"
        sidebarOpen={isOpen}
      >
        <SideNavLink
          className="sidebar-position ps-4 text-dark"
          to="/users"
          image="usericon"
        >
          <small className="nav-text">Users</small>
        </SideNavLink>
      </SideNavCat>

      {/* 5 */}
      <SideNavCat
        className={`nav-text sidenav-position text-dark ${
          isOpen ? "" : "navtext--closed"
        }`}
        name="Internal"
        image="internal"
        sidebarOpen={isOpen}
      >
        <SideNavLink
          className="sidebar-position ps-4 text-dark"
          to="/customers"
          image="customersWeSystem"
        >
          <small className="nav-text text-dark">Customers</small>
        </SideNavLink>
        <SideNavLink
          className="sidebar-position ps-4"
          to="/downloads"
          image="downloads"
        >
          <small className="nav-text">Downloads</small>
        </SideNavLink>
      </SideNavCat>
    </SideNav>
  );
};

export default SideBar;
