import React, {
  ReactElement,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import {
  Table,
  TableData,
  TableInfo,
} from "@seveniteen/bootiquestrap-ui-library";
import { ApplicationContext } from "../context/ApplicationContext";

const CountryCost: React.FC = () => {
  const applicationContext = useContext(ApplicationContext);
  const initialData: TableData = {
    total: 0,
    page: 1,
    limit: 10,
    columns: [],
    rows: [],
  };
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<TableData>(initialData);
  const [billingPeriod, setBillingPeriod] = useState<string>("");
  const [billingPeriods, setBillingPeriods] = useState<any[]>([]);
  const [tableInfo, setTableInfo] = useState<TableInfo>({
    currentPage: 1,
    step: 10,
    search: "",
  });

  const loadData = useCallback(
    (currentPage: number, step: number, search: string) => {
      setIsLoading(true);
      setTableInfo({ currentPage, step, search });
      if (billingPeriod !== "") {
        applicationContext.webApi
          ?.getDestinationDuration(
            applicationContext.customerId,
            billingPeriod,
            search,
            currentPage,
            step
          )
          .then((response) => {
            setIsLoading(false);
            setData(response);
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
      } else {
        setIsLoading(false);
      }
    },
    [applicationContext.webApi, applicationContext.customerId, billingPeriod]
  );

  useEffect(() => {
    applicationContext.webApi
      ?.getCustomerBillingPeriods(applicationContext.customerId)
      .then((biilingPeriodsResponse) => {
        setBillingPeriods(biilingPeriodsResponse);
        if (
          Array.isArray(biilingPeriodsResponse) &&
          biilingPeriodsResponse.length > 0
        ) {
          setBillingPeriod(biilingPeriodsResponse[0]);
        } else {
          setBillingPeriod("");
        }
      });
  }, [applicationContext.customerId]);

  useEffect(() => {
    loadData(tableInfo.currentPage, tableInfo.step, tableInfo.search);
  }, [billingPeriod, applicationContext.customerId]);

  const renderHeader = (): ReactElement => {
    return (
      <div className=" d-flex flex-row align-items-center ">
        <div className="pe-2">
          <small className="text-dark">Billing period:</small>
        </div>
        <div>
          <select
            className="form-select form-select-sm text-dark"
            onChange={(event) => setBillingPeriod(event.target.value)}
            disabled={isLoading}>
            {billingPeriods?.map((period: string) => (
              <option key={period} value={period}>
                {period}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };

  return (
    <Table
      tableInfo={tableInfo}
      setTableInfo={setTableInfo}
      className="table table-sm"
      data={data}
      loadData={loadData}
      isLoading={isLoading}
      renderHeader={renderHeader}
      responsive
      bordered
    />
  );
};

export default CountryCost;
