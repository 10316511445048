import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  ReactElement,
} from "react";
import { ApplicationContext } from "../context/ApplicationContext";
import "./Trunk.css";
import {
  Table,
  TableData,
  TableInfo,
  CardBody,
  CardFooter,
} from "@seveniteen/bootiquestrap-ui-library";

const Trunk: React.FC = () => {
  const applicationContext = useContext(ApplicationContext);
  const initialData: TableData = {
    total: 0,
    page: 1,
    limit: 10,
    columns: [],
    rows: [],
  };

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<TableData>(initialData);

  const [tableInfo, setTableInfo] = useState<TableInfo>({
    currentPage: 1,
    step: 10,
    search: "",
  });

  const loadData = useCallback(
    (currentPage: number, step: number, search: string) => {
      setIsLoading(true);
      setTableInfo({ currentPage, step, search });
      applicationContext.webApi
        ?.getTrunks(applicationContext.customerId, currentPage, step, search)
        .then((response) => {
          setIsLoading(false);
          setData(response);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    },
    [applicationContext.webApi, applicationContext.customerId]
  );

  useEffect(() => {
    loadData(tableInfo.currentPage, tableInfo.step, tableInfo.search);
  }, [applicationContext.customerId]);

  const handleClose = (index: number) => {
    let tempData = { ...data };
    tempData.rows[index].isExpanded = !tempData.rows[index].isExpanded;
    setData(tempData);
  };

  const trunkForm = (dummyData: any, index: number) => {
    return (
      <form>
        <CardBody className="bg-secondary bg-opacity-25">
          {/* 1 */}
          <div className="d-flex flex-column flex-md-row align-items-center mb-md-2">
            <div className="d-flex flex-column flex-md-row align-items-around align-items-md-center w-100 pe-2 position-relative">
              <label
                className="form-label text-dark ps-md-2 trunk-label-size w-25"
                htmlFor={`orderId-${index}`}
                id={`orderId-${index}`}>
                weSystems Order ID
              </label>
              <input
                className="form-control text-dark w-75 trunk-input"
                name="orderId"
                id="orderId"
                value={dummyData.orderId || ""}
                readOnly
              />
            </div>
            <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-2 position-relative">
              <label
                className="text-dark form-label ps-md-3 mb-0 trunk-label-size w-25"
                htmlFor={`trunkGroup-${index}`}
                id={`trunkGroup-${index}`}>
                Sip Trunk:
              </label>
              <input
                className="form-control text-dark w-75"
                name="trunkGroup"
                id="trunkGroup"
                value={dummyData.trunkGroup || ""}
                readOnly
              />
            </div>
          </div>
          {/* 2 */}
          <div className="d-flex flex-column flex-md-row align-items-center mb-md-2">
            <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-2 position-relative">
              <label
                className="form-label text-dark ps-md-2 trunk-label-size w-25"
                htmlFor={`city-${index}`}
                id={`city-${index}`}>
                City:
              </label>
              <input
                className="form-control text-dark w-75"
                name="city"
                id="city"
                value={dummyData.city || ""}
                readOnly
              />
            </div>
            <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-2 position-relative">
              <label
                className="text-dark form-label ps-md-3 mb-0 trunk-label-size w-25"
                htmlFor={`name-${index}`}
                id={`name-${index}`}>
                Sip Trunk Group:
              </label>
              <input
                className="form-control text-dark w-75"
                name="name"
                id="name"
                value={dummyData.name || ""}
                readOnly
              />
            </div>
          </div>
          {/* 3 */}
          <div className="d-flex flex-column flex-md-row align-items-center mb-md-2">
            <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-2 position-relative">
              <label
                className="form-label text-dark ps-md-2 trunk-label-size w-25"
                htmlFor={`country-${index}`}
                id={`country-${index}`}>
                Country:
              </label>
              <input
                className="form-control text-dark w-75"
                name="country"
                id="country"
                value={dummyData.country || ""}
                readOnly
              />
            </div>
            <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-2 position-relative">
              <label
                className="text-dark form-label ps-md-3 mb-0 trunk-label-size w-25"
                htmlFor={`type-${index}`}
                id={`type-${index}`}>
                Type:
              </label>
              <input
                className="form-control text-dark w-75"
                name="type"
                id="type"
                value={dummyData.type || ""}
                readOnly
              />
            </div>
          </div>
          {/* 4 */}
          <div className="d-flex flex-column flex-md-row align-items-center mb-md-2">
            <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-2 position-relative">
              <label
                className="form-label text-dark ps-md-2 trunk-label-size w-25"
                htmlFor={`trunkProvider-${index}`}
                id={`trunkProvider-${index}`}>
                Trunk Provider:
              </label>
              <input
                className="form-control text-dark w-75"
                name="trunkProvider"
                id="trunkProvider"
                value={dummyData.trunkProvider || ""}
                readOnly
              />
            </div>
            <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-2 position-relative">
              <label
                className="text-dark form-label ps-md-3 mb-0 trunk-label-size w-25"
                htmlFor={`partner-${index}`}
                id={`partner-${index}`}>
                Partner
              </label>
              <input
                className="form-control text-dark w-75"
                name="partner"
                id="partner"
                value={dummyData.partner || ""}
                readOnly
              />
            </div>
          </div>
          {/* 5 */}
          <div className="d-flex flex-column flex-md-row align-items-center mb-md-2">
            <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-2 position-relative">
              <label
                className="form-label text-dark ps-md-2 trunk-label-size w-25"
                htmlFor={`address-${index}`}
                id={`address-${index}`}>
                Address:
              </label>
              <input
                className="form-control text-dark w-75"
                name="address"
                id="address"
                value={dummyData.address || ""}
                readOnly
              />
            </div>
            <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-2 position-relative">
              <label
                className="text-dark form-label ps-md-3 mb-0 trunk-label-size w-25"
                htmlFor={`zipCode-${index}`}
                id={`zipCode-${index}`}>
                Zip Code:
              </label>
              <input
                className="form-control text-dark w-75"
                name="zipCode"
                id="zipCode"
                value={dummyData.zipCode || ""}
                readOnly
              />
            </div>
          </div>
          {/* 6 */}
          <div className="d-flex flex-column flex-md-row align-items-center mb-md-2">
            <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-2 position-relative">
              <label
                className="form-label text-dark ps-md-2 trunk-label-size w-25"
                htmlFor={`description-${index}`}
                id={`description-${index}`}>
                Description
              </label>
              <input
                className="form-control text-dark w-75"
                name="description"
                id="description"
                value={dummyData.description || ""}
                readOnly
              />
            </div>
            <div className="d-flex flex-column flex-md-row align-items-left align-items-md-center w-100 pe-2 position-relative"></div>
          </div>
        </CardBody>
        <CardFooter>
          <div className="d-flex flex-wrap justify-content-end">
            <div className="me-1 me-md-3 mb-2 mb-md-0">
              <button
                type="button"
                className="btn btn-md btn-secondary trunk-edit-button"
                onClick={() => handleClose(index)}>
                Close
              </button>
            </div>
          </div>
        </CardFooter>
      </form>
    );
  };

  return (
    <Table
      tableInfo={tableInfo}
      setTableInfo={setTableInfo}
      className="table table-sm"
      data={data}
      loadData={loadData}
      isLoading={isLoading}
      responsive
      bordered
      toggleEditCollapse={(index: number): void => {
        let tempData = { ...data };

        tempData.rows[index].isExpanded = !tempData.rows[index].isExpanded;
        setData(tempData);
      }}
      renderEditCollapse={(index: number): ReactElement => {
        return trunkForm(data.rows[index], index);
      }}
    />
  );
};

export default Trunk;
