import React, { useState, useContext, useEffect, useCallback } from "react";
import { ApplicationContext } from "../context/ApplicationContext";
import {
  Table,
  TableData,
  TableInfo,
} from "@seveniteen/bootiquestrap-ui-library";

const TrunkRates: React.FC = () => {
  const applicationContext = useContext(ApplicationContext);
  const initialData: TableData = {
    total: 0,
    page: 1,
    limit: 10,
    columns: [],
    rows: [],
  };

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<TableData>(initialData);
  const [tableInfo, setTableInfo] = useState<TableInfo>({
    currentPage: 1,
    step: 10,
    search: "",
  });

  const loadData = useCallback(
    (currentPage: number, step: number, search: string) => {
      setIsLoading(true);
      setTableInfo({ currentPage, step, search });
      applicationContext.webApi
        ?.getTrunks(applicationContext.customerId, currentPage, step, search)
        .then((response) => {
          setIsLoading(false);
          setData(response);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    },
    [applicationContext.webApi, applicationContext.customerId]
  );

  useEffect(() => {
    loadData(tableInfo.currentPage, tableInfo.step, tableInfo.search);
  }, [applicationContext.customerId]);
  return (
    <Table
      tableInfo={tableInfo}
      setTableInfo={setTableInfo}
      className="table table-sm"
      data={data}
      isLoading={isLoading}
      loadData={loadData}
      responsive
      bordered
    />
  );
};

export default TrunkRates;
