import React, { useState, useEffect, useContext } from "react";
import { ApplicationContext } from "../context/ApplicationContext";
import { ReactComponent as UserIcon } from "./icons/User.svg";
import { ReactComponent as PhoneIcon } from "./icons/Phone.svg";
import { ReactComponent as EnvelopeIcon } from "./icons/Envelope.svg";
import { Card, CardBody } from "@seveniteen/bootiquestrap-ui-library";

const AccountTeam = () => {
  const applicationContext = useContext(ApplicationContext);
  const [data, setData] = useState<any[]>([]);

  const loadData = () => {
    applicationContext.webApi
      ?.getAccountTeam(applicationContext.customerId)
      .then((response) => {
        setData(response);
      });
  };

  useEffect(() => {
    loadData();
  }, [applicationContext.customerId]);

  return (
    <Card className="pb-0 pt-0 border-0">
      <CardBody>
        {data.map((regionTeam) => {
          return (
            <React.Fragment key={"fragment_" + regionTeam.region}>
              <strong
                key={"region_" + regionTeam.region}
                className="text-primary">
                {regionTeam.region}
              </strong>
              <div
                className="table-responsive ps-1 pt-3"
                key={"responsive_" + regionTeam.region}>
                <table className="table table-sm" key={"table"}>
                  <thead>
                    <tr key={"region_row_" + regionTeam.region}>
                      <th style={{ width: "1%" }} />
                      <th style={{ width: "30%" }} />
                      <th style={{ width: "29%" }} />
                      <th style={{ width: "40%" }} />
                    </tr>
                  </thead>
                  <tbody key={"tbody"}>
                    {regionTeam.accountTeams.map((accountTeam: any) => {
                      return (
                        <tr key={accountTeam.id}>
                          <td key={"user_icon" + accountTeam.id}>
                            <UserIcon className="text-primary" />
                          </td>
                          <td key={"title_name_" + accountTeam.id}>
                            <div key={"title_" + accountTeam.id}>
                              {accountTeam.title}
                            </div>
                            <div key={"name_" + accountTeam.id}>
                              <small>{accountTeam.name}</small>
                            </div>
                          </td>
                          <td key={"telephone_" + accountTeam.id}>
                            <PhoneIcon className="text-primary" />
                            &nbsp;{accountTeam.telephone}
                          </td>
                          <td key={"email_" + accountTeam.id}>
                            <EnvelopeIcon className="text-primary" />
                            &nbsp;{accountTeam.email}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </React.Fragment>
          );
        })}
      </CardBody>
    </Card>
  );
};

export default AccountTeam;
