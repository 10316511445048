import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  ChartOptions,
  CategoryScale,
} from "chart.js";
import { Card, CardHeader } from "@seveniteen/bootiquestrap-ui-library";
import Icon from "./ui/Icon";

ChartJS.register(LineElement, PointElement, LinearScale, Title, CategoryScale);

type DataSets = {
  data: number[];
  borderColor: string;
  borderWidth: number;
};

type ChartData = {
  labels: string[];
  datasets: DataSets[];
};

const Voicemail: React.FC = () => {
  const hours24: string[] = [];
  for (let i = 0; i < 24; i++) {
    const hour = i < 10 ? `0${i}` : `${i}`;
    hours24.push(`${hour}:00`);
    hours24.push(`${hour}:30`);
  }

  const chartData: ChartData = {
    labels: hours24,
    datasets: [
      {
        data: [10, 20, 30, 50, 70, 120, 200, 60, 13, 6],
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 2,
      },
    ],
  };

  const chartOptions: ChartOptions<"line"> = {
    scales: {
      x: {
        title: {
          display: true,
          text: "Hours",
        },
      },
      y: {
        title: {
          display: true,
          text: "Number of Phone Calls",
        },
      },
    },
  };

  return (
    <div className="p-2 m-2">
      <div className="d-flex flex-row align-items-center">
        <Icon
          className="side-nav-link-icon"
          style={{ width: "30px", height: "30px" }}
          fill="black"
          name="voicemail"
        />
        <h3 className="p-3">Sip Trunk</h3>
      </div>

      <Card className=" border-0">
        <CardHeader className="d-flex w-100 rounded-0 border border-start-0 border-end-0 border-bottom-0 border-top-1 p-3 bg-white justify-content-center">
          <div className="btn-group" role="group" aria-label="day month year">
            <button type="button" className="btn btn-primary">
              Day
            </button>
            <button type="button" className="btn btn-primary">
              Month
            </button>
            <button type="button" className="btn btn-primary">
              Year
            </button>
            <input
              type="date"
              className="input-form bg-primary border-0 text-center rounded-end-2"
              placeholder="Select a date"
            />
          </div>
        </CardHeader>
        {/* <CardBody className="w-100 border border-start-0 border-end-0 border-bottom-1 border-top-1 p-0"> */}
        <Line data={chartData} options={chartOptions} />
        {/* </CardBody> */}
      </Card>
    </div>
  );
};
export default Voicemail;
