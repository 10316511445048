import { Card, Tab, Tabs } from "@seveniteen/bootiquestrap-ui-library";

import CustomersList from "./CustomersList";
import UploadComponent from "./UploadComponent";
import TrunkRates from "./TrunkRates";
import SumCost from "./SumCost";
import ProviderRates from "./ProviderRates";
import CountryCost from "./CountryCost";

const Customers: React.FC = () => {
  return (
    <Card className="border-0 pt-4">
      <Tabs className="nav nav-tab">
        <Tab className="nav nav-tab" title="Customers">
          <CustomersList />
        </Tab>
        <Tab className="nav nav-tab" title="Trunk Rates">
          <Card className="w-100 border-0 pt-5">
            <UploadComponent name="Trunk Rates" />
          </Card>
          <TrunkRates />
        </Tab>
        <Tab className="nav nav-tab" title="Sum Cost">
          <SumCost />
        </Tab>
        <Tab className="nav nav-tab" title="Provider Rates">
          <Card className="w-100 border-0 pt-5">
            <UploadComponent name="Provider Rates" />
          </Card>

          <ProviderRates />
        </Tab>
        <Tab className="nav nav-tab" title="Country Cost">
          <CountryCost />
        </Tab>
      </Tabs>
    </Card>
  );
};

export default Customers;
