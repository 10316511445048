import React, {
  useState,
  useContext,
  useEffect,
  ChangeEvent,
  FormEvent,
  ReactElement,
} from "react";
import { useNavigate, Link } from "react-router-dom";
import { ApplicationContext } from "../context/ApplicationContext";
import { TabletMobile, Desktop } from "../utils/breakpoints";
import { LoginResponse } from "../services/web-api/application-api";
import "./Login.css";

type RememberMe = {
  email: string;
  isOn: boolean;
};

const Login: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [rememberme, setRememberme] = useState<boolean>(false);
  const [input, setInput] = useState<{ [key: string]: string }>({});
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [formError, setFormError] = useState<boolean>(false);

  const applicationContext = useContext(ApplicationContext);

  const navigate = useNavigate();

  useEffect(() => {
    const remembermeData = localStorage.getItem("rememberme");

    if (remembermeData) {
      const tempRememberMe: RememberMe = JSON.parse(remembermeData);
      setRememberme(tempRememberMe.isOn);
      if (tempRememberMe.isOn) {
        setInput({ ...input, username: tempRememberMe.email });
      }
    }
  }, [applicationContext.webApi, input]);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrorMessage("");
    if (event.currentTarget.reportValidity()) {
      setIsLoading(true);

      localStorage.setItem(
        "rememberme",
        JSON.stringify({ email: input["username"], isOn: rememberme })
      );
      applicationContext.webApi
        ?.login(input["username"], input["password"], "", rememberme)
        .then((loginResponse: LoginResponse) => {
          applicationContext.setCustomerId(loginResponse.customer);
          navigate("/");
        })
        .catch((error) => {
          let errorMessage = "Unable to login! Error: " + error;

          if (error === 409) {
            navigate("/authentication", {
              state: {
                userName: input["username"],
                password: input["password"],
                rememberme: input["rememberme"],
              },
            });
          } else if (error === 403) {
            errorMessage = "Invalid username and/or password!";
          }

          setErrorMessage(errorMessage);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setFormError(true);
    }
  };

  const renderLoginSpinner = (): ReactElement => {
    return (
      <button
        type="submit"
        className="btn btn-primary rounded-3 justify-content-center w-100"
      >
        {isLoading ? (
          <>
            <span
              className="spinner-border spinner-border-sm me-2"
              aria-hidden="true"
            ></span>
            Login
          </>
        ) : (
          "Login"
        )}
      </button>
    );
  };

  const renderLoginForm = () => {
    return (
      <form
        className={
          "needs-validation" + (formError === true ? "was-validated" : "")
        }
        onSubmit={handleSubmit}
        noValidate
      >
        {errorMessage && (
          <div className="text-danger w-100">
            <ul>
              <li>{errorMessage}</li>
            </ul>
          </div>
        )}
        <input
          type="email"
          className="form-control email mb-1"
          placeholder="Username"
          name="username"
          onChange={handleOnChange}
          required
        />
        <span className="invalid-feedback mb-2">Username is required!</span>
        <p className="helper-text">You must use valid email address!</p>
        <input
          type="password"
          className="form-control password mb-1"
          placeholder="Password"
          name="password"
          onChange={handleOnChange}
          required
        />
        <span className="invalid-feedback mb-2">Password is required!</span>
        <p className="helper-text mb-2 pb-3">
          Password must be at leat 6 characters long
        </p>
        <div className="d-flex justify-content-between">
          <div className="pb-5">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="desktop-rememberme"
              name="rememberme"
            />
            <label
              className="form-check-label ps-1 text-center"
              htmlFor="desktop-rememberme"
            >
              <small className="mtn-1">Remember me</small>
            </label>
          </div>
          <Link to="/forgotpassword" className="text-dark underline">
            <small>Forgot password?</small>
          </Link>
        </div>
        <div className="justify-content-center align-items-center p-2 mx-5 mt-3 mt-md-0">
          {renderLoginSpinner()}
        </div>
      </form>
    );
  };

  const renderShapes = () => {
    return (
      <>
        <div id="login-shape-1" className="position-absolute rounded-circle" />
        <div
          id="login-shape-1-blur"
          className="position-absolute rounded-circle"
        />
        <div id="login-shape-2" className="position-absolute rounded-circle" />
        <div
          id="login-shape-2-blur"
          className="position-absolute rounded-circle"
        />
        <div id="login-shape-3" className="position-absolute rounded-circle" />
        <div
          id="login-shape-3-blur"
          className="position-absolute rounded-circle"
        />
        <div id="login-shape-4" className="position-absolute rounded-circle" />
        <div
          id="login-shape-4-blur"
          className="position-absolute rounded-circle"
        />
      </>
    );
  };

  return (
    <>
      <Desktop>
        <div className="d-flex align-items-center justify-content-center h-100 overflow-y-hidden overflow-x-hidden">
          <div className="position-relative p-0 m-0 border-0">
            {renderShapes()}
            <div className="login-glass-card d-flex flex-row justify-content-center align-items-center">
              <div className="d-flex alignitems-center justify-content-center w-50">
                <img
                  className="logo-img"
                  src="/images/RZ_weSystems_Logo_positiv.svg"
                  alt="Logo WeSystems"
                />
              </div>
              <div className="divider pt-4"></div>
              <div className=" w-50 px-5">{renderLoginForm()}</div>
            </div>
          </div>
        </div>
      </Desktop>
      <TabletMobile>
        <div className="d-flex position-relative overflow-y-hidden overflow-x-hidden w-100 h-100">
          <div className="card position-absolute p-0 m-0 border-0 w-100 h-100">
            {renderShapes()}
            <div className="d-flex flex-column login-glass-card-m h-100 align-items-center justify-content-center">
              <div className="d-flex flex-row">
                <img
                  className="logo-img"
                  src="/images/RZ_weSystems_Logo_positiv.svg"
                  alt="logo WeSystems"
                />
              </div>
              <div className="d-flex flex-row justify-content-center align-items-center mt-5 pt-4 m-1">
                {renderLoginForm()}
              </div>
            </div>
          </div>
        </div>
      </TabletMobile>
    </>
  );
};

export default Login;
