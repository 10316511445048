import React, { useState } from "react";
import "./UploadComponent.css";
import Icons from "./ui/Icon";
export interface UploadComponentProps {
  name: string;
  buttonText?: string;
  onButtonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const UploadComponent: React.FC<UploadComponentProps> = (
  props: UploadComponentProps
) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [message, setMessage] = useState<string>("");
  const [isDragOver, setIsDragOver] = useState(false);

  const importData = () => {
    let input = document.createElement("input");
    input.type = "file";
    const fileInput = input as HTMLInputElement;
    fileInput.onchange = () => {
      const files: FileList | null = fileInput.files;

      if (files) {
        handleUpload();
        const fileList: File[] = Array.from(files);
        setSelectedFiles(fileList);
        console.log(fileList);
      } else {
        setMessage("No file selected.");
      }
    };
    input.click();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files: FileList | null = e.dataTransfer.files;

    if (files) {
      const fileList: File[] = Array.from(files);
      setSelectedFiles(fileList);
      handleUpload();
      setIsDragOver(false);
    } else {
      setMessage("No file selected.");
    }
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleUpload = () => {
    setMessage(`${selectedFiles.length} file successfully uploaded.`);
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-center h-100 overflow-y-hidden overflow-x-hidden rounded-2 p-2 bg-white">
        <div className="card position-relative p-0 m-0 border-0 w-100">
          <div
            id="shape-1"
            className="position-absolute rounded-circle strong-5-strong "
          />
          <div
            id="shape-1-blur"
            className="position-absolute rounded-circle strong-5-strong "
          />
          <div
            id="shape-2"
            className="position-absolute rounded-circle strong-5-strong "
          />
          <div
            id="shape-2-blur"
            className="position-absolute rounded-circle strong-5-strong "
          />
          <div
            id="shape-3"
            className="position-absolute rounded-circle strong-5-strong "
          />
          <div
            id="shape-3-blur"
            className="position-absolute rounded-circle strong-5-strong "
          />
          <div
            id="shape-4"
            className="position-absolute rounded-circle strong-5-strong"
          />
          <div
            id="shape-4-blur"
            className="position-absolute rounded-circle strong-5-strong"
          />

          <div className="d-flex flex-column flex-md-row justify-content-between position-relative">
            <div className="d-flex flex-column text-dark p-1 w-100 me-2">
              <h3 className="pt-2 mb-4">
                <strong>{props.name}</strong>
              </h3>
              <div className="ms-2 pe-2 ps-2 bg-white bg-opacity-50 text-wrap rounded shadow-sm">
                <p className="pt-2 ps-3 text-dark upload-text-style w-100">
                  Easy Upload your <br />{" "}
                  <span className="text-secondary">wesystems</span> documents
                </p>
              </div>
            </div>
            <div className="d-flex flex-column flex-md-row w-100 p-1">
              <div
                className={`d-flex flex-column p-2 justify-content-center align-items-center w-100 h-100 rounded-4 shadow-sm file-drop ${
                  isDragOver ? "dragged-over" : ""
                }`}
                onDragOver={(e) => {
                  e.preventDefault();
                  setIsDragOver(true);
                }}
                onDrop={handleDrop}
                onDragLeave={handleDragLeave}>
                <div className="d-flex flex-row justify-content-center align-items-center">
                  <Icons name="uploadIcon"></Icons>
                  <p className="draganddrop-text ps-3">Drag & drop or</p>{" "}
                  <button
                    id="browse-text-btn"
                    className="btn bg-none bordered border-0"
                    onClick={importData}>
                    {" "}
                    <u>Browse</u>{" "}
                  </button>
                </div>
                <div className="w-100">
                  {selectedFiles.length > 0 && (
                    <div className="mt-3 text-dark">
                      {selectedFiles.map((file) => (
                        <p>File: {file.name}</p>
                      ))}
                      <p className="mt-3 text-label">{message}</p>
                      <div
                        className="progress"
                        role="progressbar"
                        aria-label="upload progressbar"
                        aria-valuenow={10}
                        aria-valuemin={0}
                        aria-valuemax={100}>
                        <div className="progress-bar progress-bar-striped progressbar-width bg-dark">
                          25%
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UploadComponent;
