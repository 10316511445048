import React from "react";
import SideBar from "./SideBar";
import AppNavBar from "./AppNavBar";
import { LargeDesktop, NotDesktop } from "../utils/breakpoints";
import "./Layout.css";

const Layout: React.FC = () => {
  return (
    <div className="d-flex flex-row">
      <LargeDesktop>
        <SideBar />
        <div className="layout-container-right d-flex flex-column bg-primary w-100 vh-100">
          <AppNavBar />
        </div>
      </LargeDesktop>
      <NotDesktop>
        <div className="d-flex flex-column bg-primary w-100 vh-100">
          <AppNavBar />
        </div>
      </NotDesktop>
    </div>
  );
};

export default Layout;
