import React, {
  useState,
  useEffect,
  useContext,
  createRef,
  ChangeEvent,
  KeyboardEvent,
  FormEvent,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ApplicationContext } from "../context/ApplicationContext";
import { LoginResponse } from "../services/web-api/application-api";
import { Desktop, Mobile } from "../utils/breakpoints";
import "./Authentication.css";

interface IAuthenticationProps {
  numberOfDigits?: number;
}

const Authentication: React.FC<IAuthenticationProps> = ({
  numberOfDigits = 6,
}) => {
  const [input, setInput] = useState<{ [key: string]: string }>({});
  const applicationContext = useContext(ApplicationContext);
  const navigate = useNavigate();
  const state = useLocation().state;
  const { userName, password, rememberme } = state || {};
  const inputRefs: Array<React.RefObject<HTMLInputElement>> = Array.from<
    React.RefObject<HTMLInputElement>
  >({ length: numberOfDigits }).map<React.RefObject<HTMLInputElement>>(
    (): React.RefObject<HTMLInputElement> => {
      return createRef<HTMLInputElement>();
    }
  );

  useEffect(() => {
    inputRefs[0].current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onKeyDown = (event: KeyboardEvent, id: string) => {
    let keyCode = event.key.charCodeAt(0);

    if (keyCode === 66) {
      const index = parseInt(event.currentTarget.id.split("_")[1]);
      if (index > 0) {
        setInput({ ...input, ["digit_" + index]: "" });
        inputRefs[index - 1].current?.focus();
      } else if (index === 0) {
        setInput({ ...input, ["digit_" + index]: "" });
        inputRefs[index].current?.focus();
      }
    }

    if (keyCode > 47 && keyCode < 58) {
      const index = parseInt(event.currentTarget.id.split("_")[1]);
      if (index < numberOfDigits - 1) {
        inputRefs[index + 1].current?.focus();
      }
      setInput({ ...input, [id]: event.key });
      return;
    }

    event.preventDefault();
  };

  const getAuthenticationCode = () => {
    let code = "";
    for (let i = 0; i < numberOfDigits; i++) {
      code += input["digit_" + i];
    }
    return code;
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(input);
    applicationContext.webApi
      ?.loginAuthneticationCode(
        userName,
        password,
        getAuthenticationCode(),
        rememberme
      )
      .then((loginResponse: LoginResponse) => {
        navigate("/");
        console.log(loginResponse);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderInputs = () => {
    return inputRefs.map(
      (inputRef: React.RefObject<HTMLInputElement>, index) => {
        let key = "digit_" + index;
        return (
          <input
            key={key}
            id={"digit_" + index}
            type="text"
            pattern="[0-9]"
            maxLength={1}
            className="authentication-digit-input form-control"
            placeholder="_"
            onKeyDown={(event) => {
              onKeyDown(event, "digit_" + index);
            }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              event.preventDefault();
            }}
            ref={inputRef}
            value={input["digit_" + index] || ""}
          />
        );
      }
    );
  };

  const renderShapes = () => {
    return (
      <>
        <div
          id="authentication-shape-1"
          className="position-absolute rounded-circle"
        />
        <div
          id="authentication-shape-1-blur"
          className="position-absolute rounded-circle"
        />
        <div
          id="authentication-shape-2"
          className="position-absolute rounded-circle"
        />
        <div
          id="athentication-shape-2-blur"
          className="position-absolute rounded-circle"
        />
        <div
          id="authentication-shape-3"
          className="position-absolute rounded-circle"
        />
        <div
          id="authentication-shape-3-blur"
          className="position-absolute rounded-circle"
        />
        <div
          id="authentication-shape-4"
          className="position-absolute rounded-circle"
        />
        <div
          id="authentication-shape-4-blur"
          className="position-absolute rounded-circle"
        />
      </>
    );
  };

  const renderAuthenticationForm = () => {
    return (
      <div className="card-body p-5 d-flex flex-column align-items-center text-center text-primary">
        <h4 className="text-primary mb-4">Confirm 2FA Pin</h4>
        <p className="text-info text-primary mb-5">
          Enter the code displayed on your <br />
          authentication app
        </p>
        <div className={"d-flex flex-column align-items-center"}>
          <form onSubmit={handleSubmit}>
            <div className="d-flex flex-row mb-4">{renderInputs()}</div>
            <button type="submit" className="btn btn-primary w-100 mt-5">
              Submit
            </button>
          </form>
        </div>
      </div>
    );
  };

  return (
    <>
      <Desktop>
        <div className="d-flex align-items-center justify-content-center h-100 overflow-y-hidden overflow-x-hidden">
          <div className="form position-relative">
            {renderShapes()}
            <div
              id="a-glass-card"
              className="card shadow-6 rounded-6 align-items-center py-5 border-0">
              {renderAuthenticationForm()}
            </div>
          </div>
        </div>
      </Desktop>
      <Mobile>
        <div className="d-flex position-relative overflow-y-hidden overflow-x-hidden w-100 h-100">
          <div className="card position-absolute p-0 m-0 border-0 w-100 h-100">
            {renderShapes()}
            <div className="d-flex flex-column a-glass-card h-100 align-items-center justify-content-center">
              <div className="d-flex flex-row justify-content-center align-items-center m-1">
                {renderAuthenticationForm()}
              </div>
            </div>
          </div>
        </div>
      </Mobile>
    </>
  );
};

export default Authentication;
