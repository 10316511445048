import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  ReactElement,
} from "react";
import { ApplicationContext } from "../context/ApplicationContext";
import "./ProviderRates.css";

// import Table, { TableInfo, TableData } from "./ui/Table";
import {
  Table,
  TableInfo,
  TableData,
} from "@seveniteen/bootiquestrap-ui-library";

const ProviderRates: React.FC = () => {
  const applicationContext = useContext(ApplicationContext);
  const initialData: TableData = {
    total: 0,
    page: 1,
    limit: 10,
    columns: [],
    rows: [],
  };
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<TableData>(initialData);
  const [billingPeriod, setBillingPeriod] = useState<string>("");
  const [billingPeriods, setBillingPeriods] = useState<any[]>([]);
  const [reports, setReports] = useState<any[]>([]);
  const [tableInfo, setTableInfo] = useState<TableInfo>({
    currentPage: 1,
    step: 10,
    search: "",
  });

  const loadData = useCallback(
    (currentPage: number, step: number, search: string) => {
      setIsLoading(true);
      setTableInfo({ currentPage, step, search });
      applicationContext.webApi
        ?.getTrunkroviderRates(
          applicationContext.customerId,
          search,
          currentPage,
          step
        )
        .then((response) => {
          setIsLoading(false);
          setData(response);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    },
    [applicationContext.webApi, applicationContext.customerId]
  );

  useEffect(() => {
    applicationContext.webApi
      ?.getCustomerBillingPeriods(applicationContext.customerId)
      .then((biilingPeriodsResponse) => {
        setBillingPeriods(biilingPeriodsResponse);
        if (biilingPeriodsResponse.length > 0) {
          setBillingPeriod(biilingPeriodsResponse[0]); //So we can disable the export button until we have the billing period
          applicationContext.webApi
            ?.downloadReports(
              applicationContext.customerId,
              "",
              1,
              Number.MAX_SAFE_INTEGER
            )
            .then((response) => {
              setReports(response.rows);
            });
        }
        loadData(tableInfo.currentPage, tableInfo.step, tableInfo.search);
      });
  }, [applicationContext.webApi, applicationContext.customerId]);

  useEffect(() => {
    loadData(tableInfo.currentPage, tableInfo.step, tableInfo.search);
  }, [applicationContext.customerId, billingPeriod]);

  const renderBillingDropdown = (): ReactElement => {
    if (!isLoading) {
      return (
        <div>
          <select
            className="form-select form-select-sm text-dark"
            onChange={(event) => setBillingPeriod(event.target.value)}
          >
            {billingPeriods?.map((period: string) => (
              <option key={period} value={period}>
                {period}
              </option>
            ))}
          </select>
        </div>
      );
    } else {
      return (
        <div className="d-flex flex-column">
          <div className="d-flex flex-row align-items-center input-group">
            <select
              className="form-select form-select-sm text-dark input-group"
              onChange={(event) => setBillingPeriod(event.target.value)}
            ></select>
            <span className="input-group-addon">
              <div className="d-flex flex-row justify-content-center ms-2">
                <span
                  className="spinner-grow spinner-grow-sm text-primary"
                  aria-hidden="true"
                />
              </div>
            </span>
          </div>
        </div>
      );
    }
  };

  const downloadReports = () => {
    applicationContext.webApi
      ?.downloadReports(
        applicationContext.customerId,
        billingPeriod,
        1,
        Number.MAX_SAFE_INTEGER
      )
      .then((response) => {
        setReports(response.rows);
      });
  };

  const downloadPeriods = () => {
    applicationContext.webApi
      ?.getCustomerBillingPeriods(applicationContext.customerId)
      .then((biilingPeriodsResponse) => {
        setBillingPeriods(biilingPeriodsResponse);
        if (biilingPeriodsResponse.length > 0) {
          setBillingPeriod(biilingPeriodsResponse[0]); //So we can disable the export button until we have the billing period
          applicationContext.webApi
            ?.downloadReports(
              applicationContext.customerId,
              "",
              1,
              Number.MAX_SAFE_INTEGER
            )
            .then((response) => {
              setReports(response.rows);
            });
        }
        loadData(tableInfo.currentPage, tableInfo.step, tableInfo.search);
      });
  };

  const exportString = (report: any): string => {
    if (!report) return "";
    let retVal = "";
    if (report?.done) {
      retVal = "Download ";
    } else {
      retVal = "Preparing ";
    }
    return retVal;
  };

  const selectedReports = reports?.filter(
    (report) => report.billedPeriod === billingPeriod
  );
  const customerReport = selectedReports?.find((report) => report.type === 0);
  const basicReport = selectedReports?.find((report) => report.type === 1);
  const perCallingReport = selectedReports?.find((report) => report.type === 2);
  const hasCustomer = customerReport?.done === false;
  const hasBasic = basicReport?.done === false;
  const hasPercalling = perCallingReport?.done === false;

  return (
    <>
      <div className="d-flex w-100 justify-content-between align-items-center py-4 px-3 mb-3 border-bottom">
        <div className="d-flex flex-row align-items-center justify-content-center">
          <div className="d-flex flex-row align-items-center justify-content-center">
            <div className="d-flex flex-column pe-2">
              <small className="text-dark">Billing period:</small>
            </div>
            {renderBillingDropdown()}
          </div>
        </div>
        <div>
          <div className="btn-group">
            <button
              type="button"
              className="btn btn-secondary dropdown-toggle export--btn text-white"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              disabled={billingPeriods.length === 0 || isLoading}
              onClick={() => {
                if (billingPeriod.length > 0) {
                  applicationContext.webApi
                    ?.downloadReports(
                      applicationContext.customerId,
                      billingPeriod,
                      1,
                      Number.MAX_SAFE_INTEGER
                    )
                    .then((response) => {
                      setReports(response.rows);
                    });
                }
              }}
            >
              Export as
            </button>
            <ul className="dropdown-menu dropdown-menu-end bg-white menu">
              <li>
                <button
                  className="dropdown-item text-dark dropdown-btn"
                  type="button"
                  onClick={() => {
                    if (billingPeriod.length > 0) {
                      if (customerReport?.done === true) {
                        window.open(
                          window.location.origin +
                            `/api/data/downloadreport?customerid=${applicationContext.customerId}&billingperiod=${billingPeriod}&type=0`,
                          "_self"
                        );
                      } else {
                        applicationContext.webApi?.upsertReport(
                          applicationContext.customerId,
                          billingPeriod,
                          0
                        );

                        setTimeout(downloadReports, 500);
                      }
                    }
                  }}
                  disabled={hasCustomer}
                >
                  {exportString(customerReport) + "Customer CSV"}
                  {hasCustomer && (
                    <span
                      className="spinner-border spinner-border-sm ms-4 text-primary"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              </li>
              <li>
                <button
                  className="dropdown-item text-dark dropdown-btn"
                  type="button"
                  onClick={() => {
                    if (billingPeriod.length > 0) {
                      if (perCallingReport?.done === true) {
                        window.open(
                          window.location.origin +
                            `/api/data/downloadreport?customerid=${applicationContext.customerId}&billingperiod=${billingPeriod}&type=2`,
                          "_self"
                        );
                      } else {
                        applicationContext.webApi?.upsertReport(
                          applicationContext.customerId,
                          billingPeriod,
                          2
                        );

                        setTimeout(downloadReports, 500);
                      }
                    }
                  }}
                  disabled={hasPercalling}
                >
                  {exportString(perCallingReport) + "Per Calling CSV"}
                  {hasPercalling && (
                    <span
                      className="spinner-border spinner-border-sm ms-4 text-primary"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              </li>
              <li>
                <button
                  className="dropdown-item text-dark dropdown-btn"
                  type="button"
                  onClick={() => {
                    if (billingPeriod.length > 0) {
                      if (basicReport?.done === true) {
                        window.open(
                          window.location.origin +
                            `/api/data/downloadreport?customerid=${applicationContext.customerId}&billingperiod=${billingPeriod}&type=1`,
                          "_self"
                        );
                      } else {
                        applicationContext.webApi?.upsertReport(
                          applicationContext.customerId,
                          billingPeriod,
                          1
                        );

                        setTimeout(downloadReports, 500);
                      }
                    }
                  }}
                  disabled={hasBasic}
                >
                  {exportString(basicReport) + "Basic CSV"}
                  {hasBasic && (
                    <span
                      className="spinner-border spinner-border-sm ms-4 text-primary"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <button
                  className="dropdown-item text-dark dropdown-btn"
                  type="button"
                  onClick={() => {
                    if (billingPeriod.length > 0)
                      if (
                        window.confirm(
                          `Are you sure you want to clear the data for period: ${billingPeriod}?`
                        )
                      ) {
                        applicationContext.webApi?.deleteBillingPeriod(
                          applicationContext.customerId,
                          billingPeriod
                        );

                        setTimeout(downloadPeriods, 500);
                      }
                  }}
                  disabled={billingPeriod.length === 0}
                >
                  Clear Data{" "}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Table
        tableInfo={tableInfo}
        setTableInfo={setTableInfo}
        renderColumnsColor="secondary"
        className="table table-sm"
        bordered
        data={data}
        loadData={loadData}
        responsive
        isLoading={isLoading}
      />
    </>
  );
};

export default ProviderRates;
