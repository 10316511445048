import React from "react";
import { ReactComponent as ContactIcon } from "./icons/Contact.svg";
import NOCContacts from "./NOCContacts";
import AccountTeam from "./AccountTeam";
import { Tabs, Tab, CardBody } from "@seveniteen/bootiquestrap-ui-library";

const ContactInfo: React.FC = () => {
  return (
    <div className="p-2 m-2">
      <div className="d-flex flex-row align-items-center">
        <ContactIcon />
        <h3 className="p-3">Contact List</h3>
      </div>
      <Tabs className="nav nav-tab">
        <Tab className="nav nav-tab" title="Account Team">
          <CardBody className="w-100 border-0">
            <AccountTeam />
          </CardBody>
        </Tab>
        <Tab className="nav nav-tab" title="NOC Contacts">
          <CardBody className="w-100 border-0">
            <NOCContacts />
          </CardBody>
        </Tab>
      </Tabs>
    </div>
  );
};

export default ContactInfo;
