import React, {
  useContext,
  useState,
  useCallback,
  useEffect,
  ReactElement,
} from "react";
import Icon from "./ui/Icon";
import {
  Table,
  TableData,
  TableInfo,
} from "@seveniteen/bootiquestrap-ui-library";
import { ApplicationContext } from "../context/ApplicationContext";

const Downloads: React.FC = () => {
  const applicationContext = useContext(ApplicationContext);
  const initialData: TableData = {
    total: 0,
    page: 1,
    limit: 10,
    columns: [],
    rows: [],
  };

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<TableData>(initialData);
  const [tableInfo, setTableInfo] = useState<TableInfo>({
    currentPage: 1,
    step: 10,
    search: "",
  });

  const loadData = useCallback(
    (currentPage: number, step: number, search: string) => {
      setIsLoading(true);
      setTableInfo({ currentPage, step, search });
      applicationContext.webApi
        ?.downloadReports(
          applicationContext.customerId,
          search,
          currentPage,
          step
        )
        .then((response) => {
          setData(response);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [applicationContext.webApi, applicationContext.customerId]
  );

  useEffect(() => {
    loadData(tableInfo.currentPage, tableInfo.step, tableInfo.search);
  }, [applicationContext.customerId]);

  const rowCustomRender = (i: number, j: number): ReactElement => {
    let column = data.columns[j];
    let row: any = data.rows[i];
    let strData: ReactElement =
      row[column.field] === null ? "" : row[column.field].toString();
    const billingPeriod = row["billedPeriod"];
    const type: string = row["type"].toString();
    const isDone: boolean = row["done"];

    let url: string = `/api/data/downloadreport?customerid=${applicationContext.customerId}&billingperiod=${billingPeriod}&type=${type}`;

    if (column.field === "fileName" && isDone) {
      return (
        <a href={url} target="_blank" rel="noopener noreferrer" download>
          {strData}
        </a>
      );
    } else {
      return strData;
    }
  };

  return (
    <>
      <div className="p-2 m-2">
        <div className="d-flex flex-row align-items-center">
          <Icon
            className="mt-n2"
            style={{ width: "30px", height: "30px" }}
            fill="black"
            name="downloads"
          />
          <h3 className="p-3">Downloads</h3>
        </div>
      </div>
      <Table
        tableInfo={tableInfo}
        setTableInfo={setTableInfo}
        className="table table-sm"
        data={data}
        loadData={loadData}
        isLoading={isLoading}
        rowCustomRender={rowCustomRender}
        responsive
        bordered
      />
    </>
  );
};

export default Downloads;
