import React, {
  ReactElement,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import {
  Table,
  TableData,
  TableInfo,
  Modal,
  ModalBody,
  ModalHeader,
} from "@seveniteen/bootiquestrap-ui-library";

import { ApplicationContext } from "../context/ApplicationContext";

type ProviderSum = {
  name: string;
  sum: number;
};

const SumCost: React.FC = () => {
  const applicationContext = useContext(ApplicationContext);
  const initialData: TableData = {
    total: 0,
    page: 1,
    limit: 10,
    columns: [],
    rows: [],
  };
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<TableData>(initialData);
  const [billingPeriod, setBillingPeriod] = useState<string>("");
  const [billingPeriods, setBillingPeriods] = useState<any[]>([]);
  const [showProviderSum, setShowProviderSum] = useState<boolean>(false);
  const [tableInfo, setTableInfo] = useState<TableInfo>({
    currentPage: 1,
    step: 10,
    search: "",
  });

  const loadData = useCallback(
    (currentPage: number, step: number, search: string) => {
      setIsLoading(true);
      setTableInfo({ currentPage, step, search });
      if (billingPeriod !== "") {
        applicationContext.webApi
          ?.getSumCosts(
            applicationContext.customerId,
            billingPeriod,
            search,
            currentPage,
            step
          )
          .then((response) => {
            setIsLoading(false);
            setData(response);
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
      } else {
        setIsLoading(false);
      }
    },
    [applicationContext.webApi, applicationContext.customerId, billingPeriod]
  );

  useEffect(() => {
    applicationContext.webApi
      ?.getCustomerBillingPeriods(applicationContext.customerId)
      .then((biilingPeriodsResponse) => {
        setBillingPeriods(biilingPeriodsResponse);
        if (
          Array.isArray(biilingPeriodsResponse) &&
          biilingPeriodsResponse.length > 0
        ) {
          setBillingPeriod(biilingPeriodsResponse[0]);
        } else {
          setBillingPeriod("");
        }
      });
  }, [applicationContext.customerId]);

  useEffect(() => {
    loadData(tableInfo.currentPage, tableInfo.step, tableInfo.search);
  }, [billingPeriod, applicationContext.customerId]);

  const renderHeader = (): ReactElement => {
    return (
      <div className=" d-flex flex-row align-items-center ">
        <div className="pe-2">
          <small className="text-dark">Billing period:</small>
        </div>
        <div>
          <select
            className="form-select form-select-sm text-dark"
            onChange={(event) => setBillingPeriod(event.target.value)}
            disabled={isLoading}
          >
            {billingPeriods?.map((period: string) => (
              <option key={period} value={period}>
                {period}
              </option>
            ))}
          </select>
        </div>
        <div>
          <button
            className="btn btn-sm btn-primary ms-2"
            onClick={() => setShowProviderSum(true)}
            disabled={isLoading}
          >
            Providers Sum
          </button>
        </div>
      </div>
    );
  };

  const findProvider = (
    providers: ProviderSum[],
    name: string
  ): ProviderSum | undefined => {
    return providers.find((p) => p.name === name);
  };

  const calculateSum = (): ProviderSum[] => {
    let retVal: ProviderSum[] = [];
    data.rows.forEach((row) => {
      row.perProviderCost.forEach((providerCost: any) => {
        const provider = findProvider(retVal, providerCost.provider.name);
        if (provider) {
          provider.sum += providerCost.price;
        } else {
          retVal.push({
            name: providerCost.provider.name,
            sum: providerCost.price,
          });
        }
      });
    });

    return retVal;
  };

  const renderTrunkProviderCost = (index: number): ReactElement => {
    return (
      <table className="table table-sm table-bordered">
        <thead>
          <tr>
            <th className="bg-primary">Provider</th>
            <th className="bg-primary">Price</th>
          </tr>
        </thead>
        <tbody>
          {data.rows[index].perProviderCost.map((providerCost: any) => (
            <tr key={providerCost.provider.name + `_${index}`}>
              <td>{providerCost.provider.name}</td>
              <td>{providerCost.price}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <>
      <Modal show={showProviderSum}>
        <ModalHeader onClose={() => setShowProviderSum(false)}>
          Providers Sum
        </ModalHeader>
        <ModalBody>
          <table className="table table-sm table-bordered">
            <thead>
              <tr>
                <th className="bg-primary">Provider</th>
                <th className="bg-primary">Sum</th>
              </tr>
            </thead>
            <tbody>
              {calculateSum().map((row) => (
                <tr key={row.name}>
                  <td>{row.name}</td>
                  <td>{row.sum}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </ModalBody>
      </Modal>
      <Table
        tableInfo={tableInfo}
        borderColor="primary"
        renderColumnsColor="secondary"
        spinnerColor="primary"
        spinnerTextCOlor="primary"
        setTableInfo={setTableInfo}
        className="table table-sm"
        data={data}
        loadData={loadData}
        isLoading={isLoading}
        renderHeader={renderHeader}
        toggleEditCollapse={(index: number): void => {
          const tempData = { ...data };
          tempData.rows[index].isExpanded = !tempData.rows[index].isExpanded;
          setData(tempData);
        }}
        renderEditCollapse={renderTrunkProviderCost}
        responsive
        bordered
      />
    </>
  );
};

export default SumCost;
