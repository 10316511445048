import React, {
  useEffect,
  useState,
  useContext,
  ChangeEvent,
  FormEvent,
} from "react";
import { useNavigate } from "react-router-dom";
import { ApplicationContext } from "../context/ApplicationContext";
import "./PasswordReset.css";
import { Desktop, Mobile } from "../utils/breakpoints";

const PasswordReset: React.FC = () => {
  const applicationContext = useContext(ApplicationContext);
  const [input, setInput] = useState<{ [key: string]: string }>({});
  const navigate = useNavigate();
  const [token, setToken] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [formError, setFormError] = useState<boolean>(false);

  useEffect(() => {
    const queryString = window.location.href;
    const url = new URL(queryString);
    const params = new URLSearchParams(url.search);
    const token = params.get("token");
    console.log(token);
    setToken(token ?? "");
  }, []);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.name, event.target.value);
    setInput({ ...input, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(input["password"]);
    setErrorMessage("");
    if (event.currentTarget.reportValidity()) {
      if (input["password"] !== input["changePassword"]) {
        setErrorMessage("Password do not match");
      } else {
        applicationContext.webApi
          ?.changePassword(token, input["password"] ?? "")
          .then((response: any) => {
            console.log(response);
            navigate("/");
          })
          .catch((error) => {
            let errorMessage = "Unable to Change Password! Error: " + error;

            if (error === 400) {
              errorMessage = "Bad Request";
            }
            console.log(error);
            setErrorMessage(errorMessage);
          });
      }
    } else {
      setFormError(true);
    }
  };

  const renderError = () => {
    return (
      <div className="text-danger w-100">
        <ul>
          <li>{errorMessage}</li>
        </ul>
      </div>
    );
  };

  const renderShapes = () => {
    return (
      <>
        <div
          id="password-reset-shape-1-blur"
          className="position-absolute rounded-circle"></div>
        <div
          id="password-reset-shape-1"
          className="position-absolute rounded-circle"></div>
        <div
          id="password-reset-shape-2-blur"
          className="position-absolute rounded-circle"></div>
        <div
          id="password-reset-shape-2"
          className="position-absolute rounded-circle"></div>
        <div
          id="password-reset-shape-3-blur"
          className="position-absolute rounded-circle"></div>
        <div
          id="password-reset-shape-3"
          className="position-absolute rounded-circle"></div>
        <div
          id="password-reset-shape-4-blur"
          className="position-absolute rounded-circle"></div>
        <div
          id="password-reset-shape-4"
          className="position-absolute rounded-circle"></div>
      </>
    );
  };

  const renderPasswordReset = () => {
    return (
      <>
        <div className="card-body p-5 d-flex flex-column align-items-center text-primary">
          <h4 className="justify-content-start text-primary mb-3 pb-3">
            Password Reset
          </h4>
          <p className="text-info text-primary mb-3">
            Enter a new password bellow to <br />
            change your password
          </p>
          <div className={"d-flex flex-column align-items-center"}>
            <form
              className={
                "password-reset-form needs-validation" +
                (formError === true ? "was-validated" : "")
              }
              onSubmit={handleSubmit}>
              <input
                type="password"
                className="form-control password pb-2"
                placeholder="Enter new password"
                name="password"
                onChange={handleOnChange}
                required
              />
              <span className="invalid-feedback mb-2">
                Password is required!
              </span>
              <input
                type="password"
                className="form-control password mt-3"
                placeholder="Re-enter new password"
                name="password"
                onChange={handleOnChange}
                required
              />
              <span className="invalid-feedback mt-2">
                Repeat Password is required!
              </span>
              {errorMessage && renderError()}
              <div className="d-flex flex-row mb-4"></div>
              <button
                type="submit"
                className="btn btn-primary text-white w-100 mt-4 mb-4">
                Reset Password
              </button>
            </form>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Desktop>
        <div className="d-flex align-items-center justify-content-center h-100 overflow-y-hidden overflow-x-hidden">
          <div className="form position-relative">
            {renderShapes()}
            {/* card */}
            <div
              id="glass-card"
              className="card shadow-6 rounded-6 align-items-center py-5 border-0"></div>
            {renderPasswordReset()}
          </div>
        </div>
      </Desktop>
      <Mobile>
        <div className="d-flex position-relative overflow-y-hidden overflow-x-hidden w-100 h-100">
          <div className="card position-absolute p-0 m-0 border-0 w-100 h-100">
            {renderShapes()}
            <div className="d-flex flex-column f-glass-card h-100 align-items-center justify-content-center">
              <div className="d-flex flex-row justify-content-center align-items-center m-1">
                {renderPasswordReset()}
              </div>
            </div>
          </div>
        </div>
      </Mobile>
    </>
  );
};

export default PasswordReset;
