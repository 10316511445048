import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ApplicationApi } from "./services/web-api/application-api";
import { ApplicationRestApi } from "./services/web-api/rest/application-rest-api";
import {
  ApplicationContext,
  ApplicationContextProps,
} from "./context/ApplicationContext";
import AuthorizedRoute from "./components/routing/AuthorizedRoute";
import Login from "./components/Login";
import Authentication from "./components/Authentication";
import Authenticator from "./components/Authenticator";
import Layout from "./components/Layout";
import ContactInfo from "./components/ContactInfo";
import ForgotPassword from "./components/ForgotPassword";
import VoiceManagement from "./components/VoiceManagement";
import Users from "./components/Users";
import Customers from "./components/Customers";
import Voicemail from "./components/Voicemail";
import PasswordReset from "./components/PasswordReset";
import Downloads from "./components/Downloads";

const webApi = new ApplicationApi<ApplicationRestApi>(
  new ApplicationRestApi(process.env.REACT_APP_API_URL ?? "")
);

const applicationContext: ApplicationContextProps = {
  webApi: webApi,
  customerId: -1,
  setCustomerId: () => {},
};

function App() {
  const [customerId, setCustomerId] = useState<number>(
    applicationContext.webApi?.getCredentials()?.customer ?? -1
  );
  applicationContext.customerId = customerId;
  applicationContext.setCustomerId = setCustomerId;
  return (
    <BrowserRouter>
      <ApplicationContext.Provider value={applicationContext}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/authentication" element={<Authentication />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/passwordreset" element={<PasswordReset />} />
          <Route element={<AuthorizedRoute />}>
            <Route element={<Layout />}>
              <Route path="/" element={<ContactInfo />} />
              <Route path="/voicemanagement" element={<VoiceManagement />} />
              <Route path="/voicemail" element={<Voicemail />} />
              <Route path="/users" element={<Users />} />
              <Route path="/customers" element={<Customers />} />
              <Route path="/authenticator" element={<Authenticator />} />
              <Route path="/Downloads" element={<Downloads />} />
            </Route>
          </Route>
        </Routes>
      </ApplicationContext.Provider>
    </BrowserRouter>
  );
}

export default App;
