import React, {
  ReactElement,
  useState,
  useCallback,
  useContext,
  useEffect,
} from "react";
import "./DDI.css";
import { ApplicationContext } from "../context/ApplicationContext";

import {
  Table,
  TableData,
  TableInfo,
  CardBody,
  CardFooter,
} from "@seveniteen/bootiquestrap-ui-library";

export const DDI: React.FC = () => {
  const applicationContext = useContext(ApplicationContext);
  const initialData: TableData = {
    total: 0,
    page: 1,
    limit: 10,
    columns: [],
    rows: [],
  };

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<TableData>(initialData);
  const [trunks, setTrunks] = useState<any[]>([]);
  const [tableInfo, setTableInfo] = useState<TableInfo>({
    currentPage: 1,
    step: 10,
    search: "",
  });

  const loadData = useCallback(
    (currentPage: number, step: number, search: string) => {
      setIsLoading(true);
      setTableInfo({ currentPage, step, search });
      applicationContext.webApi
        ?.getTelephoneNumbers(
          applicationContext.customerId,
          currentPage,
          step,
          search
        )
        .then((response) => {
          applicationContext.webApi
            ?.getTrunks(
              applicationContext.customerId,
              1,
              Number.MAX_SAFE_INTEGER,
              ""
            )
            .then((trunks) => {
              setTrunks(trunks.rows);
            })
            .finally(() => {
              setIsLoading(false);
            });

          setData(response);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    },
    [applicationContext.webApi, applicationContext.customerId]
  );

  useEffect(() => {
    loadData(tableInfo.currentPage, tableInfo.step, tableInfo.search);
  }, [applicationContext.customerId]);

  const handleClose = (index: number) => {
    let tempData = { ...data };
    tempData.rows[index].isExpanded = !tempData.rows[index].isExpanded;
    setData(tempData);
  };

  const ddiForm = (data: any, index: number) => {
    const trunk = trunks.find((trunk) => trunk.id === data.trunkId);
    return (
      <form>
        <CardBody className="bg-secondary bg-opacity-25">
          {/* start row 1 */}
          <div className="d-flex flex-column flex-md-row align-items-center mb-2">
            <div className="d-flex flex-column flex-md-row align-items-around align-items-md-center w-100 pe-2 position-relative">
              <label
                className="form-label text-dark ps-md-2 orderId-label-size w-25"
                htmlFor={`orderId-${index}`}
                id={`orderId-${index}`}>
                weSystems Order Id:
              </label>
              <input
                className="form-control text-dark w-75"
                name="orderId"
                id="orderId"
                value={data.orderId || ""}
                readOnly
              />
            </div>
            <div className="d-flex flex-column flex-md-row align-items-around align-items-md-center w-100 pe-2 position-relative">
              <label
                className="form-label text-dark ps-md-3 mb-0 phone-range-label-size w-25"
                htmlFor={`vendor-${index}`}
                id={`vendor-${index}`}>
                Vendor SID:
              </label>
              <input
                className="form-control text-dark w-75"
                name="vendor"
                id="vendor"
                value={data.sid || ""}
                readOnly
              />
            </div>
          </div>
          {/* end row 1 */}
          {/* start row 2 */}
          <div className="d-flex flex-column flex-md-row align-items-center mb-2">
            <div className="d-flex flex-column flex-md-row align-items-around align-items-md-center w-100 pe-2 position-relative">
              <label
                className="form-label text-dark ps-md-3 mb-0 phone-range-label-size w-25"
                htmlFor={`weSystemsSID-${index}`}
                id={`weSystemsSID-label-${index}`}>
                weSystems SID:
              </label>
              <input
                className="form-control text-dark w-75"
                name="weSystemsSID"
                id="weSystemsSID"
                value={data.sid || ""}
                readOnly
              />
            </div>
            <div className="d-flex flex-column flex-md-row align-items-around align-items-md-center w-100 pe-2 position-relative">
              <label
                className="form-label text-dark ps-md-3 mb-0 phone-range-label-size w-25"
                htmlFor={`defaultTrunk-${index}`}
                id={`defaultTrunk-label-${index}`}>
                Default Trunk:
              </label>
              <input
                className="form-control text-dark w-75"
                name="defaultTrunk"
                id="defaultTrunk"
                value={trunk?.trunkGroup ?? ""}
                readOnly
              />
            </div>
          </div>
          {/* end row 2 */}
          {/* start row 3 */}
          <div className="d-flex flex-column flex-md-row align-items-center mb-2">
            <div className="d-flex flex-column flex-md-row align-items-around align-items-md-center w-100 pe-2 position-relative">
              <label
                className="form-label text-dark ps-md-3 mb-0 phone-range-label-size w-25"
                htmlFor={`phoneNumber-${index}`}
                id={`phoneNumber-label-${index}`}>
                Phone Number:
              </label>
              <input
                className="form-control text-dark w-75"
                name="phoneNumber"
                id="phoneNumber"
                value={data.number || ""}
                readOnly
              />
            </div>
            <div className="d-flex flex-column flex-md-row align-items-around align-items-md-center w-100 pe-2 position-relative">
              <label
                className="form-label text-dark ps-md-3 mb-0 phone-range-label-size w-25"
                htmlFor={`phoneNumberTo-${index}`}
                id={`phoneNumberTo-label-${index}`}>
                Phone Number To:
              </label>
              <input
                className="form-control text-dark w-75"
                name="phoneNumberTo"
                id="phoneNumberTo"
                value={data.numberTo || ""}
                readOnly
              />
            </div>
          </div>
          {/* end row 3 */}
          {/* start row 4 */}
          <div className="d-flex flex-column flex-md-row align-items-center mb-2">
            <div className="d-flex flex-column flex-md-row align-items-around align-items-md-center w-100 pe-2 position-relative">
              <label
                className="form-label text-dark ps-md-3 mb-0 phone-range-label-size w-25"
                htmlFor={`country-${index}`}
                id={`country-label-${index}`}>
                Country:
              </label>
              <input
                className="form-control text-dark w-75"
                name="country"
                id="country"
                value={data.country || ""}
                readOnly
              />
            </div>
            <div className="d-flex flex-column flex-md-row align-items-around align-items-md-center w-100 pe-2 position-relative">
              <label
                className="form-label text-dark ps-md-3 mb-0 phone-range-label-size w-25"
                htmlFor={`city-${index}`}
                id={`city-label-${index}`}>
                City:
              </label>
              <input
                className="form-control text-dark w-75"
                name="city"
                id="city"
                value={data.city || ""}
                readOnly
              />
            </div>
          </div>
          {/* end row 4 */}
          {/* start row 5 */}
          <div className="d-flex flex-column flex-md-row align-items-center mb-2">
            <div className="d-flex flex-column flex-md-row align-items-around align-items-md-center w-100 pe-2 position-relative">
              <label
                className="form-label text-dark ps-md-3 mb-0 phone-range-label-size w-25"
                htmlFor={`address-${index}`}
                id={`address-label-${index}`}>
                Address:
              </label>
              <input
                className="form-control text-dark w-75"
                name="address"
                id="address"
                value={data.address || ""}
                readOnly
              />
            </div>
            <div className="d-flex flex-column flex-md-row align-items-around align-items-md-center w-100 pe-2 position-relative">
              <label
                className="form-label text-dark ps-md-3 mb-0 phone-range-label-size w-25"
                htmlFor={`zipCode-${index}`}
                id={`zipCode-label-${index}`}>
                Zip Code:
              </label>
              <input
                className="form-control text-dark w-75"
                name="zipCode"
                id="zipCode"
                value={data.zipCode || ""}
                readOnly
              />
            </div>
          </div>
          {/* end row 5 */}
          {/* start row 6 */}
          <div className="d-flex flex-column flex-md-row align-items-center mb-2">
            <div className="d-flex flex-column flex-md-row align-items-around align-items-md-center w-100 pe-2 position-relative">
              <label
                className="form-label text-dark ps-md-3 mb-0 phone-range-label-size w-25"
                htmlFor={`type-${index}`}
                id={`type-label-${index}`}>
                Type:
              </label>
              <input
                className="form-control text-dark w-75"
                placeholder="Type"
                name="type"
                id="type"
                value={data.type || ""}
                readOnly
              />
            </div>
            <div className="d-flex flex-column flex-md-row align-items-around align-items-md-center w-100 pe-2 position-relative">
              <label
                className="form-label text-dark ps-md-3 mb-0 phone-range-label-size w-25"
                htmlFor={`vendorEmail-${index}`}
                id={`vendorEmail-${index}`}>
                Vendor NOC email:
              </label>
              <input
                className="form-control text-dark w-75"
                name="vendorEmail"
                id="vendorEmail"
                value={data.vendorNocEmail || ""}
                readOnly
              />
            </div>
          </div>
          {/* end row 6 */}
          {/* start row 7 */}
          <div className="d-flex flex-column flex-md-row align-items-center mb-2">
            <div className="d-flex flex-column flex-md-row align-items-around align-items-md-center w-100 pe-2 position-relative">
              <label
                className="form-label text-dark ps-md-3 mb-0 phone-range-label-size w-25"
                htmlFor={`description-${index}`}
                id={`description-label-${index}`}>
                Description:
              </label>
              <input
                className="form-control text-dark w-75"
                name="description"
                id="description"
                value={data.description || ""}
                readOnly
              />
            </div>
            <div className="d-flex flex-column flex-md-row align-items-around align-items-md-center w-100 pe-2 position-relative"></div>
          </div>
          {/* end row 7 */}
        </CardBody>
        <CardFooter>
          <div className="d-flex flex-wrap justify-content-end">
            <div className="me-1 me-md-3 mb-2 mb-md-0">
              <button
                type="button"
                className="btn btn-md btn-secondary close-button"
                onClick={() => handleClose(index)}>
                Close
              </button>
            </div>
          </div>
        </CardFooter>
      </form>
    );
  };

  return (
    <Table
      tableInfo={tableInfo}
      setTableInfo={setTableInfo}
      className="table table-sm"
      data={data}
      loadData={loadData}
      isLoading={isLoading}
      responsive
      bordered
      toggleEditCollapse={(index: number): void => {
        let tempData = { ...data };
        tempData.rows[index].isExpanded = !tempData.rows[index].isExpanded;
        setData(tempData);
      }}
      renderEditCollapse={(index: number): ReactElement => {
        return ddiForm(data.rows[index], index);
      }}
    />
  );
};

export default DDI;
